import React from 'react'
import '../ServerBlockStyle.css'
import blocked from './server blocked.png'

const randomRayId = [
"4uwmmcs932c1qseh",
"0d2k8ud7ot33hktu",
"8dmp5xuablfh6s9n",
"5iua83ilrbe4eacj",
"45naqllvn9ioh5j8",
"lqye68zoenk996kq",
"pia0ac89grwf4smn",
"ln2tmgv2eztg7gx9",
"u78wz6zqjwtk02r2",
"f4e5pfaponv1nfdw",
"5xfsprn1ztwl9l1q",
"o7xwpkegyms8z2um",
"rkvo4v35pj2ldbj9",
"yzz9qt40oi0og6vz",
"7cjfb3cctvq6k38h",
"obqcyqy5azwdt8el",
"zxd5voibwxt6vrj4",
"6x3lc4u1i9fwyxvj",
"y2h0f8iy32mb5vmv",
"zv4llei906uh6x3j",
"vuh3epdtetmgrkxv",
"ppwkclfz6a90z1re",
"rh71jb0gsv2ls14n",
"klnk5qafzwydn906",
"qxtukpq2ii0pjd15",
"a79hx24qe9bq7xs4",
"si59iec663h1o9ai",
"omudf554wgu6xggj",
"avsg06hy2d4xmz53",
"cgu0mxrb8n2pd3xw",
"v67jziil7y4wnrgg",
"d3es3w5c0b9hg3cl",
"orak369wof18507m",
"wjahhp0byp7yyi51",
"sp5iucerosl7zvj0",
"vafzq2e4907d9wzd",
"x6gdkhav95vl0edq",
"kj0fj9ituyhevfeh",
"wwt8f05z5m68aa80",
"g6blfemnu6cptucr",
"lgk7cqqdzf8q3dzk",
"408vkby40m48wdxf",
"jq1p7g6eev43m8to",
"qmieauaf9w657lqg",
"jihq8ujn79j65ukm",
"9ll5o8svrh2yl0o8",
"ax10yzukgtgxrhvq",
"2k263z5vx5awp6ps",
"3dugwwnyblodtkb4",
"tuhy6eggxdc8db2m",
"95f18krwpflzilg3",
"8vajixt3om4cinff",
"ym1eg2z5cddoen0t",
"4c8wvns5efm55v2q",
"8lx9vwfw5yyc46p1",
"0cqfu1a8d9xxm236",
"135yrvf9a64ly7xj",
"gwa3t174mfm1jz26",
"u90h3lxhx6wt0qid",
"d19jfibmgiqbph7k",
"y6zmxxblyd1gwbmn",
"mjexdbcwsex2afz8",
"3xwfye4hjsgseete",
"hr8u01zxy2yzhbhz",
"2tzk9lpzwoh50y5w",
"pqbcbte9recdziz0",
"g1i7168pa24motr1",
"7klctp98wcpjtn6a",
"mf9vp2ube3m0aujx",
"5htox9k7odo851hp",
"esmtgl6bgqjh0eec",
"s90fqrfqwojd4lxr",
"xqlw8ot16zk5l6ec",
"hg0dbq9bz831oaog",
"ewz65ijtedcvuhpx",
"4bqi7ojvxs4zl6cb",
"mwhvoxep6u10rtt0",
"y3jbeawbs95dwd3y",
"0zu6zhmpugj28bep",
"0psp6o5t8p7yy82w",
"tx3m59qk57mf3t03",
"5vppxcleznxni163",
"qjok5s6tngqyagvh",
"be65o88knlwotfw8",
"m0cmqfyddvm4mj58",
"dh6ixv6k25gk2atr",
"karfcl041hmh6ilp",
"qiprcpg7k8t48488",
"nw3umrxb6gtd9plu",
"zyyv5o203ta6ilz8",
"gdfk5by1i82sse9a",
"a7gdgvikqyqqpcrf",
"phka4xxr9cffntvr",
"gqx5jbjr2c1r2w1p",
"vv0rlqqiso8cyejd",
"8kfzjvfht1qvkgm0",
"zj0q94vtfefj51rw",
"y3f30aaelruq4hno",
"mf4k5thurd5o2x88",
"2i8ayevefs5bdeml",
"uoc2ul5q0gq1fg6c",
"ubktsg9mpxt4pp4l",
"f62001ezkg9apig2",
"uohyp6mclsfiwg8k",
"ince92ycwycpvhpm",
"5gys9bw4gp8im8qo",
"fhdfv87xmzym6u7x",
"gzpobleh6nd0bosd",
"vbc7zqudvwv0kn81",
"bz808np24muuxnnx",
"ic9iwwk3eatfjqee",
"783119l80j6u2lty",
"gcgxwew3swzch9j6",
"ks4yhxmaxwce6dho",
"pk51pjpnfqk6kyc4",
"v71f917osdstfvkl",
"09ulo8ch00wagd1h",
"vsnpjcdk0rq5wge9",
"u6i8zmy9jlhtaqs9",
"5n9mgu60hnctrucy",
"9e73mq2j7zxims72",
"no7qveflk5o9l97f",
"8ypu8qg4xo0y7n4o",
"9j6peqa6617zcxen",
"a8957fyyczdl8io0",
"cjnk0kwg78ltxenr",
"acwa9i8i12wtr1mt",
"zvmvdio50zgsy5q7",
"lzpi570o66wwfmgq",
"w0tbpq49hd1swran",
"rzpfq9786u7a90jc",
"toezv6o7a0e9qy9m",
"us4xqs1jskbypc6q",
"e5552469facex34y",
"l34m1wlag6vza9p7",
"nhkxqbasr9celnb1",
"xm1v0zddc6s9m9xu",
"zgpi8w2awvietyyz",
"uyz5etutsb2r1v92",
"vg3ug7enen3pgi61",
"sinzpkv4n607qlgy",
"gq9rx3h56iz4vkju",
"9rmaisr0t8kd4j04",
"mkgwsxfn2fe3iv05",
"l3y1565fja52d053",
"hpwxdj35r3vtsr5v",
"pj6anpxaymdhyifx",
"xyd540xdtiftega3",
"i8a5xj19imjy9jz7",
"oplb06i53atz107a",
"ftrcx4n2axxqjvlf",
"fsyptq9x3iaehznk",
"b82k1f09xu2cpv62",
"61666w4np48fsz7n",
"f6yywft739ljsjvu",
"iooozvbug0zric2h",
"59no2sj0qko2g3qo",
"pewb4o04a2ybp1cp",
"i6sqkxe91ku66bh7",
"q3rvynb39nb9ogvn",
"cfmxs5ghozi3e2jf",
"dvz218hndn6vdnbh",
"i5mot2sk6m7xpbgq",
"m534gumbadc8bh2s",
"e2a1estaxf8jlqy1",
"trv5lsr5rxs4tf6u",
"ib95r6ux8iilgme3",
"yz69lst9v64k4xds",
"a6n2q1p4ejv5k1h9",
"4e3wpofaosz0konu",
"16ngpy7ybywmehcb",
"t8qyl3vceffhij48",
"58q0kcpa1p3znpc9",
"xs7h1r1rj4ry0hq5",
"ios2gm7pvjzcgyrd",
"2nypfp5igkls6p7o",
"mxiuhvsyztpsf05f",
"7v1ecrujtibvaaq0",
"hhqe6pg55tbgjlr9",
"oyvi0uk2p6vr2es6",
"hyka19y9pq8b72kj",
"zrddtbvuhb3wllef",
"h764m5n9rcu8ufd9",
"o3ynglhtynpphy8w",
"bzr770wh1705tj3j",
"hrce9mdgv31qp2ne",
"4cte00kcyxjy9lsc",
"y9vl93ufzqegvrof",
"b43tl6sd9igwdr0d",
"eupr0chnjqfesjw3",
"fun2su6kpifmuhs9",
"xxtcp4tvamwgargo",
"lwcacojg409qq03s",
"swmpok9axws2s75l",
"sgyju1hudi71h0mb",
"w4qfzsyhnply7ssz",
"dkqzp4qin5f6goyh",
"rsdzznxh2ycuztvz",
"42xt02ggx5bgxpll",
"4ytwii4qnvi40d44",
"uwbxl7geu0wvv94m",
"gtgbpy6kiny625vf",
"0up3iohf2clxo7ph",
"b5fpnx1r4nu8rzgx",
"oekagcr9h5z1dd5t",
"siee8mtwm6ahciw5",
"oiqvwc3hb1iisgxp",
"zu2m8dv03gy2yjpa",
"ze57zo23q14uci1x",
"6h0mdfz4y4851tcu",
"fdxr3tvm5llovi5e",
"qcbi4wsjeriyoc8j",
"ek9n9aeuxn4i4rg2",
"wfe5olubpdcdb9hi",
"dxhszidbctdas575",
"8oy2ozvifap630ih",
"y5rjlu02daozxpzh",
"e9xxcfnv1iniab7c",
"frkd66kh9tmykbpd",
"8fmpwd85nypso4ne",
"rx9h2xmnlujutw0w",
"30cn22yr1ww722oj",
"6ukyvac5ei8vh06b",
"42dgokwqxuucr29e",
"7jpeeoffsq7052y1",
"em1mh6iyg8tfo94p",
"ilnzkmh7x17p10mr",
"s0r4eeyrw7jq1148",
"q2ywwknbza70jv8j",
"lgbbyumd9kqzd440",
"0zcth9228nk75a00",
"669oqfrhmjc4pqbc",
"lvxo5v3gpjk0bn2q",
"7zrfair2195cmftj",
"ypthbka3sek6r6ox",
"dczatblsfgij5ntj",
"eysfxatkaf9jy0rp",
"1dh01n63w5n1gy79",
"kisrmt6vlreoin1s",
"ewd68btus186u97g",
"rl0k7vk3j2ut4446",
"apywenvcj1823rbi",
"xohpl9xvqhmjboy2",
"eohb8dmllvofik54",
"jfidc0zmn0or7w1g",
"h1ngqtu3d3pjhj07",
"4l1bat72l5sfeol3",
"50zee7ef8ibu0qaf",
"k6zjb5lx408j50g4",
"8fqtfl3jv30upsjt",
"gpb45tduvmcl5q4q",
"3f0tfv2jf07dogrk",
"2vrq0c4mxr977xkl",
"t3y8wxqin3ax6a72",
"9p2ujv4huk25emyp",
"zr9l0q5yquznin95",
"v0cs6gg35qvmz6y8",
"b980gs3f8mz3gebr",
"315m3ysfyopv7a84",
"2xqp36bguhkxnh4x",
"aoz8978j3hsg6v23",
"lnsr8imp6g4ycfz5",
"lq81epaj2zsoswo1",
"dx8kle81c9vhp5zu",
"bbk06fnbbmbz5xtg",
"c6wmun4n98jrwbkd",
"l0jfcid7habw57a8",
"k72tk9trpjgbmn12",
"f2f3ayctv8nn0nr8",
"q12s6z7w6iqt139i",
"ub1rhxe4qk2cprm6",
"vtbxeptp8ite73om",
"ihhrjgrbmx0u4n8p",
"3y3q64gcywhouf4m",
"9is4h6jnj2j7b9nt",
"sz85ncrrg2psjbm6",
"80or5m6box5cddbl",
"ymjhh5ciq25hcu8u",
"x1kbqg8prcug1tzz",
"ouog7r19mluwzda9",
"di75ydhbpyp212a1",
"jrr507csdhs47u72",
"734z9xby5t9aj8ds",
"hod74sjpeh8erbef",
"gybox9jteghmgld0",
"o8l9t3vidl745f1q",
"54v64it2r9eqqrmt",
"pcvgl8zfxr52v6nf",
"87xyw8aby5ncxv9r",
"s7j07l4hqmi4tjxp",
"urcpd61ldrw8x4yy",
"mf8d50wdu45tushl",
"kywv501uial0ltmq",
"jnp06xmaxh0ixtqw",
"oin0a3zkb2mnfhli",
"jf0buykwpt312c9q",
"okpkat2144ujdl3n",
"7iqbrt2cq94urcaa",
"ndm6ys8vewejuk93",
"oe80sy5dvu74pr2w",
"coqvelb8hs9vlgju",
"717g7nn82hmgejqb",
"hox507e6si2k56hd",
"gv4uzy679nztqkxw",
"cotmdx5q2jhd83i8",
"hw6s96hk3fkmfdou",
"rv4kzmoh19t2z73j",
"3slwk93ud7vri4rg",
"hq9x4zmy9ygmfufl",
"7cj7idtz4ovqgeqj",
"5g1s0w7if888fniz",
"8khgt03j6jld8tpg",
"9r7auqvgp7cdhawq",
"cdv6jzyrlf1hm53k",
"nkbzoicpc1t60hfd",
"mycoj8a46mowsn4a",
"zcwfggvohr7hm6um",
"wwmaoje72p4qwjjb",
"555mcvlm7w95jrfi",
"qkg3nk8lzxxzziqb",
"s3cf95yld8zhslro",
"s781buqo7t3jlg1c",
"1njxjcebobndypl6",
"y6y1szz6nc1ol36s",
"qgiuclzq6v01jp4j",
"n0zof5p0yds0502w",
"rl3dzbeb3kzk5ilp",
"y54hj0lvc3ef1r1x",
"ih78vqc0gpqpm4ml",
"ir061tl1gkdd3mzp",
"w9oqjib0wd6gjlr8",
"atpyhfr8gprsx1w2",
"3bdsccvg1cngw2eg",
"xgrc6utw6bc0usai",
"tr5p68o7o026z0tk",
"2b5pzf45cs8txnkd",
"nbilz7qws3ihe4nh",
"5k00yxt6zcxee9hg",
"zkx90e58u0rlotzc",
"jqfq9hrzs9kdoed3",
"2fluiibcv0usvo1l",
"8roeffea32xta9pp",
"tphwy42w55xgl1x6",
"1g9oktp1zfctu3dw",
"ut8xa5ienbhhp7kh",
"z5wjz9wpxfjgcxge",
"13eoqm8yd0bp354f",
"bpzme4bolmfpgwbr",
"ix2kscel94eiclva",
"dlsfx6b2z2lldswx",
"81cl0l9615dy5c1e",
"11cvw2nq5p9dhuup",
"y7atq1qgxtqyyru3",
"uwsfsoe571vrpx0h",
"du02rgzlk8lyj49d",
"8b4h2he70k6yfkor",
"qkf8tiodo6em6yc5",
"gh60eq5v643tm2ag",
"bwz83m11n4bzv41z",
"ihj9l4mw8vdw6k5p",
"4mnd5pn5akrs2m9t",
"pe0u1p58yeoyo27u",
"nwmi37pvw9zh68lq",
"a01yljyrak6781ba",
"4fidnznlx9cha3aa",
"7kvku5dwkwfj5p15",
"vtoq9qhk4o58iunq",
"s8m27kojqkf6hrvi",
"7fvfq1vidqfhxypq",
"zirszsmlse59p1dg",
"qv9u0cc22fem7z9l",
"asdy92nzyc27nxp2",
"u6lj1pv8a1oqgsrg",
"m7fsio4qcklcn9t9",
"10gfbbgl95lpihri",
"7jj863wfae3382yi",
"8s7wykmibsrsmcj5",
"pcq98rvea7efki5z",
"0j5g163mn2t027x1",
"ma1aatl64w953pss",
"a5j4ubay7a8rfel7",
"wyc65qxhq0bfq5am",
"ozcmxdmledy4v91r",
"r68zc62ijbzagmte",
"t12ahwvej0rfc2g8",
"wetezwvcsr6102n9",
"inelxerrkoiojjf5",
"ru4wcwk37g43p9mi",
"2vy0cz07dyocw5lj",
"50ngdk55qycg7dft",
"1iot8pfnqs9dnkt1",
"z5ik95f418b772so",
"lx9g93f8vw45bm6p",
"g5j3ppq0usvfi1am",
"v7kdom109e2mvtyn",
"occw61h0dufy50bo",
"9joor2805ns6xnae",
"ar7wtvbyldcsdetm",
"ahyz0tzbi1lv83ii",
"svdvl1zgmk509bz1",
"zllnf41xsi8d6rm8",
"lztg9coxcr5ncbb1",
"oddsgwsmr4dja2g6",
"bbp0ec3i0tjdueqj",
"1lakagkm154dev5z",
"7f6eowhqgwn2yhpj",
"43aejtkhgzhahs1x",
"igvlwzy7iz6kfvef",
"wh6qzuv0s2tvp2yc",
"082rg6hsiv6ltogp",
"zejq36kfisr7ujp2",
"spcz5ghf544grfhp",
"b9ym1j3yp768bzs8",
"uwpcamuf0cdy1qya",
"3ogwxxt87j7ray8m",
"yw8uia6uddjqcska",
"8gkrhi6kcrhv2one",
"4uqnfk15k7xczu83",
"l6m3pi7xkl141vw1",
"ti7vizmt45g68tyo",
"00s76qrwu92mqlf1",
"6dxcwdx6uopxxagt",
"66b3m0s9xi84ju9y",
"2chwp6ny4ve3jnvx",
"xgnepigrygm0g35g",
"e6cgabd00c1u1xdu",
"08t0odseipgqwb9x",
"472uufyebn2gpo7q",
"anc1smupldvr8uo7",
"j5l6iu4m2mq3k6ri",
"d1gt37kbdm9y9d98",
"awf0tvx6r0k95q7f",
"rdyk66l2v6y08xxj",
"xul72n49zygner1n",
"x5yvc4ynln79lfyz",
"8ukxkiy5q3gy03zx",
"4s7pkujolpi6oxtq",
"k8r1arl7d62738b0",
"rqxofyimm5wsy7i9",
"gkxuu7vr5km6mr6h",
"prh56dzae3u82eyk",
"fk8uqi3vgag1854o",
"djazaeqe0pz402hn",
"36y17d80nqkma965",
"xl28spw3pt5t2m5z",
"0fa7b20gaspm0uvn",
"56vva1ifnkl0ydw9",
"xpli40vj0nf84926",
"y3xcc9rnqoovhvd6",
"zihjozygd795ddgg",
"flqcsf3lvu3lfu9j",
"3w4l9iun81l6rjgo",
"ilzt0c8e4871fp8q",
"7e49cw9fh7x7jgnd",
"0lslo7kskc22gduj",
"fivs7p8704a0wvwu",
"ee0xaep919pcyb78",
"i464qy5tf9kd7o2o",
"b97edfjqrnvnn0mt",
"zytu94qcf4y1q8te",
"rjgs8ke7fgozl0c1",
"091894nzwocbxw8i",
"cr730w90fw5how7h",
"xr9xmj60glx2aqg0",
"q0y2bcqt89uf3jyt",
"nsmwyabnvpz5tz3w",
"z5uf6tj69ugkh2l4",
"qzveghckcmrk33j1",
"avuywc81fs4mycxu",
"wp5l87fj8y50txa5",
"ujfutcli6fqwj1bq",
"zvqcsl421w0vlvpy",
"0dchvtsbv8wpyvcg",
"bfmra9cvxrzinxo8",
"9vp92rsvob9zu20f",
"51tz3pf8uwfva3im",
"dguck37fnt0emp75",
"i8zxh2y5zb9w710m",
"pgagakrime9tkflu",
"t2udizkvxwje3v63",
"y8qx0p6pasw1uh01",
"qpim605wgpoynfrm",
"gsske0u64gjiiyjs",
"zs8nfkv2gviujziq",
"uu2je7l3p1dv4nn7",
"31tykibhm4v6ib2z",
"u8lu5y9rg312em5v",
"8bkyn7appiq06gi4",
"qdifrbc1zihdn5cu",
"9t544nxcrpl468oc",
"n9reied4hyo5cxrz",
"7jddb9alaqtf8w95",
"1zwipy718g0t2dqf",
"latbh4yujjdhsp2h",
"qvru50wpjg3ewrlv",
"iqx4lsseo5vvv4gz",
"v9dz2bvcey1vzomn",
"dkp3bq6zsqrv1uz9",
"yc4guf8gdoiqkj0n",
"wv3u51ccbsl6o12u",

]
const ServerBlock = () => {
    return (
        <>
            <div id="cf-wrapper">
                <div class="cf-alert cf-alert-error cf-cookie-error" id="cookie-alert" data-translate="enable_cookies">Please enable cookies.</div>
                <div id="cf-error-details" class="cf-error-details-wrapper">
                    <div class="cf-wrapper cf-header cf-error-overview">
                        <h1 data-translate="block_headline">Sorry, you have been blocked</h1>
                        <h2 class="cf-subheadline"><span data-translate="unable_to_access">You are unable to access</span> ndax.in</h2>
                    </div>

                    <div class="cf-section cf-highlight">
                        <div class="cf-wrapper">
                            <img src={blocked} height="450" width="1050" style={{marginBottom:'-65px',marginLeft:'-20px'}} alt="error" />
                        </div>
                    </div>

                    <div class="cf-section cf-wrapper">
                        <div class="cf-columns two">
                            <div class="cf-column">
                                <h2 data-translate="blocked_why_headline">Why have I been blocked?</h2>

                                <p data-translate="blocked_why_detail">This website is using a security service to protect itself from online attacks. The action you just performed triggered the security solution. There are several actions that could trigger this block including submitting a certain word or phrase, a SQL command or malformed data.</p>
                            </div>

                            <div class="cf-column">
                                <h2 data-translate="blocked_resolve_headline">What can I do to resolve this?</h2>

                                <p data-translate="blocked_resolve_detail">You can email the site owner to let them know you were blocked. Please include what you were doing when this page came up and the Cloudflare Ray ID found at the bottom of this page.</p>
                            </div>
                        </div>
                    </div>

                    <div class="cf-error-footer cf-wrapper w-240 lg:w-full py-10 sm:py-4 sm:px-8 mx-auto text-center sm:text-left border-solid border-0 border-t border-gray-300">
                        <p class="text-13">
                            <span class="cf-footer-item sm:block sm:mb-1">Cloudflare Ray ID: <strong class="font-semibold">{randomRayId[Math.floor(Math.random() * (500 - 1 + 1)) + 1]}</strong></span>
                            <span class="cf-footer-separator sm:hidden">&bull;</span>
                            <span id="cf-footer-item-ip" class="cf-footer-item hidden sm:block sm:mb-1">
                                Your IP:
                                <button type="button" id="cf-footer-ip-reveal" class="cf-footer-ip-reveal-btn">Click to reveal</button>
                                <span class="hidden" id="cf-footer-ip">2401:4900:1c33:4ab0:a151:7ca0:1073:6f1f</span>
                                <span class="cf-footer-separator sm:hidden">&bull;</span>
                            </span>
                            <span class="cf-footer-item sm:block sm:mb-1"><span>Performance &amp; security by</span> <a rel="noopener noreferrer" href="https://www.cloudflare.com/5xx-error-landing" id="brand_link" target="_blank">Cloudflare</a></span>

                        </p>
                    </div>


                </div>
            </div>
        </>
  )
}

export default ServerBlock
