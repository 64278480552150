import './App.css';
import ServerBlock from '../src/components/Server block page/components/ServerBlock'

function App() {
  return (
    <div className="App">
     <ServerBlock/>
    </div>
  );
}

export default App;
